import type { ComputedRef, Ref } from 'vue';
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  toRef,
} from 'vue';

import { CustomerFieldType, CustomerRegistrationType } from '@leon-hub/api-sdk';
import type { Maybe } from '@leon-hub/api-sdk';
import { FormControlType } from '@leon-hub/form-utils';
import { assert, isEnumOfType } from '@leon-hub/guards';

import type { VFormRef } from 'web/src/components/Form/components/VForm/types';
import { useFormPhoneErrorPatterns } from 'web/src/components/Form/composables';
import { isFormUiSchemaDefaultFields } from 'web/src/components/Form/guards';
import type {
  FormExternalErrors,
  FormOutput,
  FormSchema,
  FormUiSchema,
} from 'web/src/components/Form/types';
import type { TabsItem } from 'web/src/components/Tabs';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import type { VTabsProps } from 'web/src/components/Tabs/VTabs/types';
import { useSiteConfigStore } from 'web/src/modules/core/store/useSiteConfigStore';
import useCountryStore from 'web/src/modules/country/store/useCountryStore';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import useDialogsStore from 'web/src/modules/dialogs/store/useDialogsStore';
import { useI18n } from 'web/src/modules/i18n/composables';
import { EXCLUDED_COUNTRY_DIALOG_ID } from 'web/src/modules/registration/store/composables/useExcludedCountryDialog';
import useRegistrationIdleRedirectStore from 'web/src/modules/registration/store/useRegistrationIdleRedirectStore';
import type { RegistrationMainStageEmits } from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/types';
import { useSimplifiedRegistrationStore } from 'web/src/modules/registration/submodules/simplified-registration/store';
import getFormSnapshotFromFilledFields from 'web/src/modules/registration/utils/getFormSnapshotFromFilledFields';
import RegistrationRouterParametersHolder from 'web/src/modules/registration/utils/RegistrationRouterParametersHolder';
import {
  formatRegistrationFormFields,
  getDefaultCountryCode,
  saveActiveTabForLogin,
} from 'web/src/modules/registration/utils/utils';

import { useRegistrationHook } from './useRegistrationHook';

interface RegistrationMainStageComposable {
  freeBetBannerUrl: Ref<string>;
  registrationHintMessageLink: Ref<Maybe<string> | undefined>;
  isRegistrationHintMessageEnabled: Ref<boolean | undefined>;
  bannerUrl: Ref<string>;
  isTermsCheckboxHidden: ComputedRef<boolean>;
  tabsItems: Ref<TabsItem[]>;
  customErrors: Ref<FormExternalErrors>;
  isFormPending: Ref<boolean>;
  isLargerPaddingBottom: ComputedRef<boolean>;
  schema: Ref<Optional<FormSchema>>;
  uiSchema: Ref<Optional<FormUiSchema>>;
  onSubmit(data: FormOutput): void;
  emitInput(data: FormOutput): void;
  tabsProperties: Ref<VTabsProps>;
  onTabClick(tabName: CustomerRegistrationType): void;
  formRef: Ref<Optional<VFormRef>>;
  restoreFromSnapshot(): void;
}

export function useMainStage(emits: RegistrationMainStageEmits): RegistrationMainStageComposable {
  const simpleRegStore = useSimplifiedRegistrationStore();
  const siteConfigStore = useSiteConfigStore();
  const countryStore = useCountryStore();
  const regIdleStore = useRegistrationIdleRedirectStore();
  const { phoneErrorPatterns } = useFormPhoneErrorPatterns();
  const { showDialog } = useDialogs();
  const dialogsStore = useDialogsStore();

  const selectedCountryCode = ref(null);
  const { $translate } = useI18n();
  const formRef = ref<VFormRef>();

  let predefinedEmail: string | null = null;

  const freeBetActionBannerUrl = toRef(simpleRegStore, 'freeBetActionBannerUrl');

  const bannerUrl = toRef(simpleRegStore, 'regBannerUrl');
  const registrationType = toRef(simpleRegStore, 'registrationType');
  const availableForms = toRef(simpleRegStore, 'availableForms');
  const customErrors = toRef(simpleRegStore, 'regFormCustomErrors');
  const isFormPending = toRef(simpleRegStore, 'isFormPending');
  const currentFormProperties = toRef(simpleRegStore, 'currentFormProperties');
  const formFilledFields = toRef(() => simpleRegStore.formFilledFields);
  const isRegistrationDisabled = toRef(siteConfigStore, 'isRegistrationDisabled');
  const geoIpCountryCode = toRef(countryStore, 'geoIpCountryCode');
  const currentDialog = toRef(dialogsStore, 'currentDialog');
  const isRegistrationHintMessageEnabled = toRef(() => siteConfigStore.isRegistrationHintMessageEnabled);
  const registrationHintMessageLink = toRef(() => siteConfigStore.registrationHintMessageLink);
  const schema = computed(() => currentFormProperties.value?.schema);

  const uiSchema = computed<Optional<FormUiSchema>>(() => {
    const currentSchema = currentFormProperties.value?.uiSchema;
    if (!currentSchema) {
      return undefined;
    }

    const { fields } = { ...currentSchema };
    assert(isFormUiSchemaDefaultFields(fields));

    if (fields.EMAIL && predefinedEmail) {
      Object.assign(fields.EMAIL, {
        ...fields.EMAIL,
        default: predefinedEmail,
      });
    }

    const defaultCountryCode = getDefaultCountryCode(geoIpCountryCode.value, fields);

    const phoneValidationPattern = {
      '^[1-9][0-9]{4,14}$': $translate('WEB2_PHONE_INTERNATIONAL_FORMAT').value,
    };

    return {
      ...currentSchema,
      fields: formatRegistrationFormFields({
        fields,
        selectedCountryCode: selectedCountryCode.value,
        isRegistrationDisabled: isRegistrationDisabled.value,
        defaultCountryCode,
      }),
      submitButton: {
        label: $translate('WEB2_REGISTRATION_SUBMIT').value,
        disabled: isRegistrationDisabled.value,
      },
      validatorErrorPatterns: {
        byWidget: phoneErrorPatterns.value,
        byPattern: {
          [FormControlType.Phone]: phoneValidationPattern,
          [FormControlType.PhoneInput]: phoneValidationPattern,
          [FormControlType.PhoneCountriesSelectorInput]: phoneValidationPattern,
        },
      },
    };
  });

  const isTermsCheckboxHidden = computed<boolean>(() => {
    const termsFieldCheckbox = uiSchema.value?.fields?.[CustomerFieldType.TERMS_CHECKBOX];
    if (termsFieldCheckbox?.hidden !== undefined) {
      return termsFieldCheckbox.hidden;
    }
    return true;
  });

  const freeBetBannerUrl = computed(() => freeBetActionBannerUrl.value ?? null);

  const tabsItems = computed<TabsItem[]>(() => availableForms
    .value
    .map((form) => form.registrationType)
    .map((formKey) => ({
      id: formKey,
      label: formKey === CustomerRegistrationType.SIMPLE_EMAIL
        ? $translate('WEB2_REG_TAB_NAME_EMAIL').value
        : $translate('WEB2_REG_TAB_NAME_PHONE').value,
    })));

  const tabsProperties = computed<VTabsProps>(() => ({
    items: tabsItems.value,
    activeId: registrationType.value ?? undefined,
    fullWidth: true,
    type: TabsType.UPPERCASE,
  }));

  const isLargerPaddingBottom = computed<boolean>(() => currentDialog.value?.dialog?.id === EXCLUDED_COUNTRY_DIALOG_ID);

  function saveCurrentSnapshot(): void {
    const snapshotToSave = formRef.value?.getSnapshot();
    if (snapshotToSave) {
      simpleRegStore.saveFormFilledFields(snapshotToSave.formData);
    }
  }

  function onTabClick(tabName: CustomerRegistrationType): void {
    assert(
      isEnumOfType(CustomerRegistrationType)(tabName),
      'tabName should be of type CustomerRegistrationType',
    );
    void saveCurrentSnapshot();
    simpleRegStore.setCurrentRegistrationType(tabName);
  }

  function emitInput(data: FormOutput): void {
    emits('input', data);
  }

  function onSubmit(data: FormOutput): void {
    if (!registrationType.value) {
      return;
    }
    if (registrationType.value === CustomerRegistrationType.SIMPLE_PHONE) {
      simpleRegStore.setMetrikaPhoneCheck('clickRegistrationButton');
    }
    simpleRegStore.setFormPendingStatus(true);
    saveActiveTabForLogin(registrationType.value);
    void saveCurrentSnapshot();
    emits('submit', data);
  }

  function showModalIsRegistrationDisabled(): void {
    if (isRegistrationDisabled.value) {
      showDialog({
        presetName: PresetName.ALERT_WARNING,
        options: {
          title: $translate('WEB2_REGISTRATION_DISABLED_TITLE').value,
          confirmMessage: $translate('WEB2_REGISTRATION_DISABLED').value,
          iconName: undefined,
          isCloseAllowed: true,
          buttons: [{
            label: $translate('WEB2_REGISTRATION_DISABLED_BTN').value,
            action: DialogAction.CONFIRM,
          }],
        },
      });
    }
  }

  useRegistrationHook();

  onBeforeMount(() => {
    const email = RegistrationRouterParametersHolder.getInputEmail();
    if (email) {
      simpleRegStore.setCurrentRegistrationType(CustomerRegistrationType.SIMPLE_EMAIL);
      predefinedEmail = email;

      RegistrationRouterParametersHolder.setInputEmail(undefined);
    }
  });

  onMounted(() => {
    regIdleStore.setStartTimeRegistration(Date.now());
    showModalIsRegistrationDisabled();
  });

  onBeforeUnmount(() => {
    void saveCurrentSnapshot();
  });

  function restoreFromSnapshot(): void {
    const snapshot = getFormSnapshotFromFilledFields(formFilledFields.value);
    if (snapshot) {
      formRef.value?.restoreFromSnapShot(snapshot);
    }
  }

  return {
    formRef,
    isTermsCheckboxHidden,
    freeBetBannerUrl,
    bannerUrl,
    tabsItems,
    tabsProperties,
    onTabClick,
    onSubmit,
    schema,
    uiSchema,
    customErrors,
    isFormPending,
    emitInput,
    restoreFromSnapshot,
    isLargerPaddingBottom,
    isRegistrationHintMessageEnabled,
    registrationHintMessageLink,
  };
}
